.article-content-home,
.article-content-inner,
.article-content-post {
  > :not(section) {
    @apply flex flex-col max-w-[1156px] mx-auto px-s24 lg:px-0;
  }

  > ul,
  > ul ul {
    @apply list-disc pl-s24 text-md;
  }

  > ol,
  > ol ol {
    @apply list-decimal pl-s24 text-md;
  }

  > p {
    @apply text-md mb-s24;

    &:empty {
      @apply hidden;
    }
  }

  > .wp-block-paragraph-wrapper p {
    @apply text-md;
    margin-bottom: 24px !important;

    &:empty {
      @apply hidden;
    }
  }

  .wp-block-heading {
    @apply text-black font-medium mb-s16;
  }

  h1.wp-block-heading {
    @apply text-h2 lg:text-h1;
  }

  h2.wp-block-heading {
    @apply text-h3 lg:text-h2;
  }

  h3.wp-block-heading {
    @apply text-h4 lg:text-h3;
  }

  h4.wp-block-heading {
    @apply text-h5 lg:text-h4;
  }

  h5.wp-block-heading {
    @apply text-h5;
  }

  h6.wp-block-heading {
    @apply text-lg;
  }

  section + .wp-block-heading-wrapper {
    @apply my-s32 lg:my-s80;
  }
}

.article-content-inner {
  &:has(> section:first-child) {
    @apply pt-0;
  }

  &:has(> section:last-child) {
    @apply pb-0;
  }
}
