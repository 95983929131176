/* stylelint-disable selector-max-compound-selectors */

.LeadGeneratorAd {
  .wpcf7-form {
    @apply min-w-full mt-0 mx-0;

    br {
      @apply hidden;
    }

    fieldset {
      @apply flex flex-row flex-auto gap-s16;

      div {
        @apply w-full;
      }
    }

    label {
      @apply hidden text-left text-base font-medium mb-s8 text-black;
    }

    input,
    textarea {
      @apply w-full p-s16 bg-[#f8f8f8] border border-[#ddd];
    }

    input {
      @apply h-[48px] rounded-[50px];
    }

    textarea {
      @apply rounded-[20px];
    }

    input[type='submit'] {
      @apply w-full min-w-[150px] text-base text-white font-medium bg-transparent cursor-pointer leading-none transition ease-in-out duration-150 hover:border-secondary hover:bg-secondary active:scale-90;
      border: 1px solid #000;
    }

    .wpcf7-not-valid-tip {
      @apply block text-left mt-[10px] font-bold;
    }

    p > span {
      @apply block text-left text-black;
    }

    .wpcf7-response-output {
      @apply text-black text-center my-[24px] mx-0;
    }

    .wpcf7-spinner {
      @apply block my-[10px] mx-auto;
    }
  }

  img {
    @apply h-[400px] md:h-[580px] object-cover rounded-br32;
  }

  &.LeadGeneratorAdOne {
    p > span {
      @apply text-center;
    }
  }
}

.wpcf7-form {
  @apply min-w-full md:min-w-[560px] max-w-full md:max-w-[560px] mt-[40px] mx-0 md:mx-auto;

  br {
    @apply hidden;
  }

  fieldset {
    @apply grid;

    div {
      @apply mb-[10px];
    }
  }

  label {
    @apply block text-left text-base font-medium mb-s8 text-black;
  }

  input,
  textarea {
    @apply w-full p-s16 bg-[#f8f8f8] border border-[#ddd] placeholder:text-dark-grey;
  }

  input {
    @apply h-[48px] rounded-[50px];
  }

  textarea {
    @apply rounded-[20px];
  }

  input[type='submit'] {
    @apply block w-[122px] min-w-[122px] mx-auto text-base text-white font-medium bg-primary border-0 cursor-pointer leading-none transition ease-in-out duration-150 hover:border-secondary hover:bg-secondary active:scale-90;
  }

  .wpcf7-not-valid-tip {
    @apply block text-left mt-[10px] font-bold;
  }

  p > span {
    @apply block text-left text-black;
  }

  .wpcf7-response-output {
    @apply text-black text-center mt-[24px] mb-[100px] mx-0;
  }

  .wpcf7-spinner {
    @apply block my-[8px] mx-auto;
  }
}
