.HeroCarousel {
  .js-carousel-title {
    &[data-active-slide='true'] {
      @apply block;
    }
    &[data-active-slide='false'] {
      @apply hidden;
    }
  }
}
