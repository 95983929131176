.PhotoVideoGallery {
  .swiperGallery1 {
    @apply lg:px-s80;

    .VideoPlayer {
      @apply aspect-square rounded-br32;
    }

    img,
    iframe {
      @apply pointer-events-none;
    }

    swiper-slide {
      @apply brightness-50;

      &.swiper-slide-active {
        @apply brightness-100;
      }
    }
  }

  .swiperGallery2 {
    .VideoPlayer {
      @apply aspect-[3/2] rounded-br32;
    }
  }
}
