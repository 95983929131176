.gs-i {
  font-size: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.gs-i::before {
  content: '';
  display: inline-block;
  mask-size: contain;
  mask-repeat: no-repeat;
}

/* icon sizes */

.gs-i-sm::before {
  height: 16px;
  width: 16px;
}

.gs-i-md::before {
  height: 24px;
  width: 24px;
}

.gs-i-lg::before {
  height: 32px;
  width: 32px;
}

.gs-i-xl::before {
  height: 64px;
  width: 64px;
}

/* icon colors */
.gs-i-primary::before {
  @apply bg-primary;
}

.gs-i-secondary::before {
  @apply bg-secondary;
}

.gs-i-black::before {
  @apply bg-black;
}

.gs-i-white::before {
  @apply bg-white;
}

/* icon names */

.gs-i-relume::before {
  mask-image: url('~@assets/icons/icon-relume.svg');
}

.gs-i-star::before {
  mask-image: url('~@assets/icons/icon-star.svg');
}

.gs-i-hamburger::before {
  mask-image: url('~@assets/icons/icon-hamburger.svg');
}

.gs-i-arrow-left::before {
  mask-image: url('~@assets/icons/icon-arrow-l.svg');
}

.gs-i-arrow-right::before {
  mask-image: url('~@assets/icons/icon-arrow-r.svg');
}

.gs-i-facebook::before {
  mask-image: url('~@assets/icons/icon-social-fb.svg');
}

.gs-i-instagram::before {
  mask-image: url('~@assets/icons/icon-social-ig.svg');
}

.gs-i-linkedin::before {
  mask-image: url('~@assets/icons/icon-social-in.svg');
}

.gs-i-youtube::before {
  mask-image: url('~@assets/icons/icon-social-yt.svg');
}

.gs-i-twitter::before {
  mask-image: url('~@assets/icons/icon-social-x.svg');
}

.gs-i-chevron-down::before {
  mask-image: url('~@assets/icons/icon-chevron-down.svg');
}

.gs-i-close::before {
  mask-image: url('~@assets/icons/icon-close.svg');
}

.gs-i-play::before {
  mask-image: url('~@assets/icons/icon-play.svg');
}
