html {
  scroll-behavior: smooth;
}

:root {
  --swiper-pagination-color: #f6bca2;
  --progress-bar-width: auto;
}

body[data-scroll-disable='true'] {
  @apply overflow-hidden;
}

iframe {
  @apply w-full h-auto aspect-video;
}

.wrapper {
  @apply grid grid-cols-4 gap-s8 max-w-[1220px] mx-auto items-center px-s24 lg:grid-cols-12 lg:gap-s24;
}

.content-wrapper {
  @apply min-h-screen;
}

.is-active {
  @apply flex;
}

.is-hidden {
  @apply hidden;
}

.is-open {
  @apply rotate-180 transition-transform;
}

//overlap-settings
.is-overlap-50 {
  @apply lg:-my-[50px];

  div.acf-block-preview & {
    @apply my-0;
  }
}

@layer utilities {
  .font-thin {
    font-variation-settings: 'wght' 100;
  }

  .font-extralight {
    font-variation-settings: 'wght' 200;
  }

  .font-light {
    font-variation-settings: 'wght' 300;
  }

  .font-normal {
    font-variation-settings: 'wght' 400;
  }

  .font-medium {
    font-variation-settings: 'wght' 500;
  }

  .font-semibold {
    font-variation-settings: 'wght' 600;
  }

  .font-bold {
    font-variation-settings: 'wght' 700;
  }

  .font-extrabold {
    font-variation-settings: 'wght' 800;
  }

  .font-black {
    font-variation-settings: 'wght' 900;
  }
}

.wysiwyg {
  ul {
    @apply flex flex-col gap-y-s16 mt-s32;
  }

  ul li {
    @apply flex items-center gap-x-s16 text-left text-base;
  }

  a {
    @apply text-primary underline;
  }

  p:not(:last-of-type) {
    @apply mb-s24;
  }

  &.li-centered {
    ul li {
      @apply justify-start md:justify-center;
    }
  }

  &.icon-box-white {
    ul {
      @apply mt-s32;

      li {
        &::before {
          content: url('~@assets/icons/icon-box-white.svg');
        }
      }
    }
  }

  &.icon-check-pink {
    ul {
      @apply mt-s48;

      li {
        &::before {
          content: url('~@assets/icons/icon-check-pink.svg');
        }
      }
    }
  }
}

.calendly-inline-widget {
  height: 100% !important;

  iframe {
    height: 700px !important;
  }
}

.is-slide-active {
  @apply opacity-100 block;
}

.loader {
  @apply border-4 border-white border-t-4 border-t-primary rounded-full w-s32 h-s32 mx-auto animate-spin;
}

// hide play button and thumbnail when video is playing
.js-video-iframe {
  &[data-play-status='loading'] {
    .js-play-icon {
      @apply hidden;
    }
  }

  &[data-play-status='ready'] {
    .js-loader {
      @apply hidden;
    }

    .js-play-icon {
      @apply block;
    }
  }

  &[data-play-status='playing'] {
    .js-play-btn,
    .js-video-thumbnail {
      @apply hidden;
    }
  }

  &[data-play-status='paused'] {
    .js-loader {
      @apply hidden;
    }

    .js-play-btn,
    .js-video-thumbnail {
      @apply block;
    }
  }
}
