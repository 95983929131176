.btn {
  @apply inline-block font-medium border px-s32 py-s12;
}

.btn-primary {
  @apply bg-primary text-white border-primary rounded-full text-center w-full md:w-auto transition ease-in-out duration-300 hover:border-secondary hover:bg-secondary active:scale-90;
}

.btn-secondary {
  @apply bg-secondary text-white rounded-full text-center w-full md:w-auto transition ease-in-out duration-300 hover:border-secondary hover:bg-secondary active:scale-90;
}

.btn-light {
  @apply bg-white text-black rounded-full text-center w-full md:w-auto transition ease-in-out duration-300 hover:border-secondary hover:bg-secondary hover:text-white active:scale-90;
}

.btn-dark {
  @apply bg-dark-grey text-white rounded-full text-center w-full md:w-auto transition ease-in-out duration-300 hover:border-secondary hover:bg-secondary hover:text-white active:scale-90;
}

.btn-outline-light {
  @apply border border-white text-white rounded-full text-center w-full md:w-auto transition ease-in-out duration-300 hover:border-secondary hover:bg-secondary active:scale-90;
}

.btn-outline-dark {
  @apply border border-black text-black rounded-full text-center w-full md:w-auto transition ease-in-out duration-300 hover:border-secondary hover:bg-secondary hover:text-white active:scale-90;
}
