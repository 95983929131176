// hide play button and thumbnail when video is playing
.js-VideoPlayer[data-variant='default'] {
  &[data-play-status='loading'] {
    .js-play-icon {
      @apply hidden;
    }
  }

  &[data-play-status='ready'] {
    .js-loader {
      @apply hidden;
    }

    .js-play-icon {
      @apply block;
    }
  }

  &[data-play-status='playing'] {
    .js-play-btn,
    .js-video-thumbnail {
      @apply hidden;
    }
  }

  &[data-play-status='paused'] {
    .js-loader {
      @apply hidden;
    }

    .js-play-btn,
    .js-video-thumbnail {
      @apply block;
    }
  }
}
