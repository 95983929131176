.Board {
  .description {
    @apply max-h-[12em] overflow-hidden relative line-clamp-6;
  }

  .description.expanded {
    @apply max-h-none line-clamp-none; /* Remove height limitation when expanded */
  }

  .read-more {
    @apply visible cursor-pointer font-semibold text-primary block mt-s24 text-center lg:text-right hover:text-secondary duration-300;
  }

  .read-more::after {
    content: 'Read More';
  }

  .description.expanded + .read-more::after {
    content: 'Read Less';
  }
}
